import { useEffect, useState, useRef, useContext } from "react";
import Header from "../../Components/Header2";
import axios from "axios";
import { settings } from "../../Components/utils/Settings";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import { Button, Row, Col, Select, Form, Input } from "antd";
import Footer from "../../Components/Footer";
import { useHistory } from "react-router";
import PupilContext from "../../Components/context/PupilContext";
import {
  FiArrowDown,
  FiArrowUp,
  FiChevronsLeft,
  FiChevronsRight,
  FiMinus,
  FiPlus,
} from "react-icons/fi";

//*********************************** */ modal css Starts */ **************************************/////////
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
//*********************************** */ modal css ends */ **************************************/////////

const ExtractionReport = (props) => {
  //*********************************** */ Usestates Starts */ **************************************/////////
  const history = useHistory();
  // const { activePupilId, setActivePupilIdHandler, pupilInfo } =
  //   useContext(PupilContext);



  const UserRoleId = localStorage.getItem("userRole");
  const inputRef = useRef(null);
  const [Editform] = Form.useForm();
  const [commentsdata, setCommentsdata] = useState([]);
  const [commentsdataLa, setCommentsdataLa] = useState([]);
  const [latestcomm, Setlatestcomm] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenLa, setIsOpenLa] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [tablemap, settablemap] = useState([]);
  const [addComment, setAddComment] = useState(false);
  const [EditComment, setEditComment] = useState(false);
  const [FormDatas, setFormDatas] = useState({});
  const [Updatedvalue, setUpdatedvalue] = useState("");
  const [comment, setComment] = useState("");
  const [historytype, setHistorytype] = useState({});
  const [updateddata, setupdateddata] = useState(false);
  const [updateddataedit, setupdateddataedit] = useState(false);
  const [deleteComment, setDeleteComment] = useState(false);
  const [TableData, setTableData] = useState([]);
  const SchoolId = localStorage.getItem("schoolId");
  const [searchfilter, setSearchFilter] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [paginationvalue, setpaginationvalue] = useState();
  const itemsPerPageOptions = [5, 10, 20, 50]; // Different options for items per page
  const itemsPerPageOptions1 = [10, 20, 50]; // Different options for items per page
  const itemsPerOptions = [10,15, 50, 100, 200, 500]; // Different options for items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [Lapage, setLapage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]); // Default value
  const [sortingValue, setSortingValue] = useState("");
  const [itemsPerPageLa, setItemsPerPageLa] = useState(itemsPerPageOptions1[0]);
  const [Atten,setAtten] = useState('')
  const [cictype,setcictype] = useState('')
  const [SearchDataValue,setSearchDataValue] = useState('')

  //const [selectedStudent, setSelectedStudent] = useState(activePupilId);

  const [itemsPage, setItemsPage] = useState(itemsPerOptions[0]); // Default value
  const items = Array.from(
    { length: commentsdata?.length },
    (_, index) => `Item ${index + 1}`
  );
  const items1 = Array.from(
    { length: TableData?.length },
    (_, index) => `Item ${index + 1}`
  );

  const items2 = Array.from(
    { length: latestcomm?.length },
    (_, index) => `Item ${index + 1}`
  );
  const totalPages = Math.ceil(items.length / itemsPerPage);
  const totalPagesLa = Math.ceil(items2.length / itemsPerPageLa);
  const totalPagesMain = Math.ceil(items1.length / itemsPage);
  const [telnum, setTelnum] = useState(null);
  const [contactname, setcontactname] = useState(null);
  const [truestate, settruestate] = useState(null);
  const [updatedtel, setupdatedtel] = useState("");
  const [Emailvalue, setEmailvalue] = useState(null);
  const [editableIndex, setEditableIndex] = useState(null);
  const [selectedClient, setselectedClient] = useState(423685);
  const [SelectedText, setSelectedText] = useState("");
  const [innerTable, setinnertable] = useState([]);
  //*********************************** */ Usestates Ends */ **************************************/////////

  //*********************************** */ useEffect Starts */ **************************************/////////

console.log(SearchDataValue)


  useEffect(() => {
    setPageLoading(true);

    // 423685
    const url = `${settings.API_HOST}/rest/fetchPepExtractionData?schoolId=${selectedClient}&customSorting=${sortingValue}`;

    axios.get(url).then((res) => {
      
      const Extractiondata = res?.data?.dataExtractionList;
      setTableData(Extractiondata);
      const tabledata = Extractiondata.map((items) => {
       
        return {
          attendanceStatus: items?.attendanceStatus,
          dataExtractionEmail: items?.dataExtractionEmail,
          schoolName: items?.schoolName,
          urn: items?.urn,
          noOfPupilInWonde: items?.noOfPupilInWonde,
          noOfPupilInSchool: items?.noOfPupilInSchool,
          dataExtractionTel: items?.dataExtractionTel,
          dataExtractionName: items?.dataExtractionName,
          latestCommentFromAsset: items?.latestCommentFromAsset,
          latestCommentFromLA: items?.latestCommentFromLA,
          schoolId: items?.schoolId,
        };
      });
      
      settablemap(tabledata);
    });
  }, [updateddata, updateddataedit, deleteComment, sortingValue,selectedClient,SearchDataValue]);

  const handleClient = (e) => {
    const SchoolId = e.target.value;
    setselectedClient(e.target.value);
    setSelectedText(e.target.text);
    console.log(e.target.text)
   const Cictype =e.target[e.target.selectedIndex].id
    //setcictype(e.target[e.target.selectedIndex].id )
    https: axios
      .get(
        `${settings.API_HOST}/rest/fetchPepExtractionData?schoolId=${SchoolId}&cicType=${Cictype}`
      )
      .then((response) => {
       

        const Extractiondata = response?.data?.dataExtractionList;
        setTableData(Extractiondata);
        const tabledata = Extractiondata.map((items) => {
         
          return {
            attendanceStatus: items?.attendanceStatus,
            dataExtractionEmail: items?.dataExtractionEmail,
            schoolName: items?.schoolName,
            urn: items?.urn,
            noOfPupilInWonde: items?.noOfPupilInWonde,
            noOfPupilInSchool: items?.noOfPupilInSchool,
            dataExtractionTel: items?.dataExtractionTel,
            dataExtractionName: items?.dataExtractionName,
            latestCommentFromAsset: items?.latestCommentFromAsset,
            latestCommentFromLA: items?.latestCommentFromLA,
            schoolId: items?.schoolId,
          };
        });
        settablemap(tabledata);
      })
      .catch((err) => {
        toast.warn(
          "Something went wrong. Please reload this page or try to select a pupil.",
          {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: false,
          }
        );
      });
  };
  //*********************************** */ useEffect Ends */ **************************************/////////

  //*********************************** */ modal function starts */ **************************************/////////
  function openModal(e, id) {
   
    const historytype = "ASSET";
    const updatedurlcomments = `${settings.API_HOST}/rest/fetchSchoolComments`;
    setHistorytype({ historytype, id });

    var bodyFormData = new FormData();

    bodyFormData.append("dataExtraction.SchoolId", id);
    bodyFormData.append("dataExtraction.historyType ", historytype);

    axios({
      method: "post",
      url: updatedurlcomments,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      const commentData = res?.data?.commentFromAsset;
      setpaginationvalue(commentData.length);

      const commentArray = commentData?.map((items) => {
       
        return {
          comment: items?.comment,
          stringCreatedDate: items?.stringCreatedDate,
          writtenBy: items?.writtenBy,
          id: items?.id,
          schoolId: items?.schoolId,
          userId: items?.userId,
          commentsFrom: items?.commentsFrom,
        };
      });
      setCommentsdata(commentArray);
    });
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  //*********************************** */ modal function ends */ **************************************/////////

  //*********************************** */ modal LA function starts */ **************************************/////////
  function openModalLa(e, id) {
    
    const historytype = "LA";
    const updatedurlcomments = `${settings.API_HOST}/rest/fetchSchoolComments`;
    setHistorytype({ historytype, id });
    var bodyFormData = new FormData();

    bodyFormData.append("dataExtraction.SchoolId", id);
    bodyFormData.append("dataExtraction.historyType ", historytype);

    axios({
      method: "post",
      url: updatedurlcomments,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      
      const commentDataLA = res?.data?.commentFromLa;
      Setlatestcomm(commentDataLA);

      const commentArrayLA = commentDataLA?.map((items) => {
       
        return {
          comment: items?.comment,
          stringCreatedDate: items?.stringCreatedDate,
          writtenBy: items?.writtenBy,
          id: items?.id,
          schoolId: items?.schoolId,
          userId: items?.userId,
          commentsFrom: items?.commentsFrom,
        };
      });
      setCommentsdataLa(commentArrayLA);
    });
    setIsOpenLa(true);
  }

  function closeModalLa() {
    setIsOpenLa(false);
  }
  //*********************************** */ modal LA function ends */ **************************************/////////

  //*********************************** */ new comment starts */ **************************************/////////
  function openModalNew() {
    setAddComment(true);
    setIsOpen(false);
    setIsOpenLa(false);
  }
  //*********************************** */ new comment ends */ **************************************/////////

  //*********************************** */ edit comment starts */ **************************************/////////
  function openModalEdit(e, id, commentId, schoolid, user, cmfrom) {
    setIsOpenLa(false);
    Editform.setFieldsValue({
      comment: id,
    });
    setFormDatas({
      comment: { commentId, schoolid, id, cmfrom, user },
    });

    

    setIsOpen(false);
    setEditComment(true);
  }
  //*********************************** */ edit comment ends */ **************************************/////////

  const ChangedValue = (e) => {
    setUpdatedvalue(e.target.value);
  };

  //*********************************** */ delete comment starts */ **************************************/////////
  function deletButton(e, id, schoolId, userId) {
    if (deleteComment == true) {
      setDeleteComment(false);
    }

   
    const deletedurlcomments = `${settings.API_HOST}/rest/removeSchoolComment`;

    var bodyFormData = new FormData();

    bodyFormData.append("pepSchoolComment.schoolId", schoolId);
    bodyFormData.append("pepSchoolComment.id", id);
    bodyFormData.append("pepSchoolComment.userId", userId);

    axios({
      method: "post",
      url: deletedurlcomments,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      
      const successmessage = res?.data?.successmessage;
      const errormessage = res?.data?.errormessage;
     
    
      if (successmessage != null) {
        toast.success(successmessage);
        setDeleteComment(true);
        setIsOpen(false);
        setIsOpenLa(false);
      } else {
        toast.error(errormessage);
      }
    });
  }
  //*********************************** */ delete comment starts */ **************************************/////////

  //*********************************** */ comment update function start */ **************************************/////////
  async function updateComment(e, id) {
    if (updateddata == true) {
      setupdateddata(false);
    }

    const commId = FormDatas?.comment?.commentId;
    const SchoolId = FormDatas?.comment?.schoolid;
    const commentfrom = FormDatas?.comment?.user;
    const UserId = FormDatas?.comment?.cmfrom;

    const updateUrl = `${settings.API_HOST}/rest/updateSchoolComment`;

    var bodyCommentData = new FormData();

    bodyCommentData.append("pepSchoolComment.id", commId);
    bodyCommentData.append("pepSchoolComment.schoolId", SchoolId);
    bodyCommentData.append("pepSchoolComment.commentsFrom", commentfrom);
    bodyCommentData.append("pepSchoolComment.comment", Updatedvalue);
    bodyCommentData.append("pepSchoolComment.userId", UserId);

    axios({
      method: "post",
      url: updateUrl,
      data: bodyCommentData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
     
      const successmessage = res?.data?.successmessage;
      
    
      if (successmessage != null) {
        setEditComment(false);
        setupdateddata(true);
        toast.success(successmessage);
      }
    });
  }
  //*********************************** */ comment update function ends */ **************************************/////////

  //*********************************** */ attendance update function start */ **************************************/////////
  async function AttendanceUpdate(e, item) {
    setAtten(e)

    const updatedurl = `${settings.API_HOST}/rest/updateSchoolAttendanceStatus`;

    var bodyFormData = new FormData();

    bodyFormData.append("dataExtraction.SchoolId", item);
    bodyFormData.append("dataExtraction.attendanceStatus", e);

    axios({
      method: "post",
      url: updatedurl,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
     

      const successmessage = res?.data?.successmessage;
      const errormessage = res?.data?.errormessage;
      if (successmessage != null) {
        toast.success(successmessage);
        if (updateddata == true){
          setupdateddata(false)
        }
        setupdateddata(true)

      } else {
        toast.error(errormessage);
      }
      
    });
  }
  //*********************************** */ attendance update function ends */ **************************************/////////

  const addNewComment = () => {
    if (updateddataedit == true) {
      setupdateddataedit(false);
    }

    const userId = localStorage.getItem("userId");
    
    const commentsFrom = historytype?.historytype;
    const schoolId = historytype?.id;

    const addedNewComment = `${settings.API_HOST}/rest/createSchoolComment`;
    var bodyCommentData = new FormData();

    bodyCommentData.append("pepSchoolComment.comment", comment);
    bodyCommentData.append("pepSchoolComment.schoolId", schoolId);
    bodyCommentData.append("pepSchoolComment.commentsFrom", commentsFrom);
    bodyCommentData.append("pepSchoolComment.userId", userId);

    axios({
      method: "post",
      url: addedNewComment,
      data: bodyCommentData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
     

      const successmessage = res?.data?.successmessage;
     
    
      if (successmessage != null) {
        toast.success(successmessage);
        setAddComment(false);
        setIsOpenLa(false);
        setupdateddataedit(true);
      }
    });
  };

  const getCommentAdd = (e) => {
    setComment(e.target.value);
  };

  //*********************************** */search function start */ **************************************/////////
  const SearchData = (event) => {
   
    event.preventDefault();
   
     const searchTerm = event.target.value.toLowerCase();
     //setSearchDataValue(searchTerm)
    //  if (SearchDataValue !== ''){
    //   searchTerm = SearchDataValue
    //  }

    const Mainsearch = TableData.filter((item) => {
      // Check if the item is an object and contains string properties
      if (typeof item !== "object") {
        return false; // Skip non-object items
      }

      // Check if any string property contains the search term
      for (const key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm)
        ) {
          return true; // Found a match
        }
      }

      return false; // No match found
    });
    

    const Updatedtable = Mainsearch.map((items) => ({
      attendanceStatus: items?.attendanceStatus,
      dataExtractionEmail: items?.dataExtractionEmail,
      schoolName: items?.schoolName,
      urn: items?.urn,
      noOfPupilInWonde: items?.noOfPupilInWonde,
      noOfPupilInSchool: items?.noOfPupilInSchool,
      dataExtractionTel: items?.dataExtractionTel,
      dataExtractionName: items?.dataExtractionName,
      latestCommentFromAsset: items?.latestCommentFromAsset,
      latestCommentFromLA: items?.latestCommentFromLA,
      schoolId: items?.schoolId,
    }));

    settablemap(Updatedtable);
   
  };

  //*********************************** */search function ends */ **************************************/////////

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const commentsdata1 = commentsdata.slice(indexOfFirstItem, indexOfLastItem);

  const indexOfPerLastItem = page * itemsPage;
  const indexOfSecondItem = indexOfPerLastItem - itemsPage;
  const commentsdata2 = tablemap.slice(indexOfSecondItem, indexOfPerLastItem);

  const indexOfLaLastItem = Lapage * itemsPerPageLa;
  const indexOfLaSecondItem = indexOfLaLastItem - itemsPerPageLa;
  const commentsdata3 = latestcomm.slice(
    indexOfLaSecondItem,
    indexOfLaLastItem
  );

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to the first page when changing items per page
  };

  const handleItemsPageChange = (event) => {
    const newItemsPage = parseInt(event.target.value);
    
    setItemsPage(newItemsPage);
    setPage(1);
  };

  const handleItemsPageChangeLa = (event) => {
    const newItemsPage = parseInt(event.target.value);
    
    setItemsPerPageLa(newItemsPage);
    setLapage(1);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handlePageChangeLa = (newPage) => {
    if (newPage >= 1 && newPage <= totalPagesLa) {
      setLapage(newPage);
      setItemsPerPageLa(newPage);
    }
  };

  const handleMainPagesChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPagesMain) {
    
      setPage(newPage);
    }
  };

  // const handlePageChange = (newPage) => {
  //   setCurrentPage(newPage);
  // };

  const StudentShow = (item, item2) => {
   
    settruestate(item2);
    if (truestate != null) {
      settruestate(null);
    }
    

    const urls = `${settings.API_HOST}/rest/fetchSubSchoolsKidsData`;

    var bodyFormData = new FormData();

    bodyFormData.append("schoolId", selectedClient);
    bodyFormData.append("subSchoolId", item);

    axios({
      method: "post",
      url: urls,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      
      const responsedata = res?.data?.schoolsPupilsList;

      const studentData = responsedata?.map((items) => {
       
        return {
          dob: items?.dob,
          fullName: items?.fullName,
          wondeStatus: items?.wondeStatus,
          yearGroup: items?.yearGroup,
          pupilId: items?.pupilId,
        };
      });
      setinnertable(studentData);
    });
  };

  const handleEditComplete = (item, name, event) => {
    // event.preventDefault();
    

    UpdateTel(item, name);
    // alert("saved");
    // Update the item with the edited value
    // For example: updateDataAtIndex(index, editedValue);
    // setEditableIndex(null);
  };

  const handleKeyPress = (item, name, event) => {
    if (event.key === "Enter") {
      UpdateTel(item, name);
    }
  };

  const UpdateTel = async (item, name) => {
    if (updateddataedit == true) {
      setupdateddataedit(false);
    }

    const updatetel = `${settings.API_HOST}/rest/updateSubSchoolsData`;
    var bodyCommentData = new FormData();

    bodyCommentData.append("subSchoolData.schoolId", item);
    bodyCommentData.append("subSchoolData.columnName", name);
    bodyCommentData.append("subSchoolData.value", updatedtel);

    axios({
      method: "post",
      url: updatetel,
      data: bodyCommentData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
     
      const successmessage = res?.data?.successmessage;
     // setSearchDataValue(updatedtel)
      if (successmessage != null) {
        setTelnum(null);
        setEmailvalue(null);
        setcontactname(null);
        setSearchDataValue(SearchDataValue +'')
        setupdateddataedit(true)
        toast.success(successmessage);
      }
    });
  };

  const showArrow = (sortingParameter) => {
    if (sortingValue === `${sortingParameter} asc`) return <FiArrowUp />;

    if (sortingValue === `${sortingParameter} desc`) return <FiArrowDown />;
  };

  const setCustomSorting = (sortingColumn) => {
   
    if (!sortingValue) {
      setSortingValue(`${sortingColumn} asc`);
    }
    if (sortingValue?.split(" ")[1] === "asc") {
      setSortingValue(`${sortingColumn} desc`);
    }
    if (sortingValue?.split(" ")[1] === "desc") {
      setSortingValue(`${sortingColumn} asc`);
    }
  };

  // const HandleRedirection = (id, e) => {
  //   //setActivePupilIdHandler(id);
 
  //   // const encodedURL = encodeURI(id);
  //   history.push(`../attendance-caller/`);
  // };

  return (
    <>
      <div className="custom-head">
        {/* /////////////////////////////////////////////////////////// modal comments for assets starts /////////////////////////////////////////////////////////// */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          ariaHideApp={false}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <button className="btn-close" onClick={closeModal}>
            X
          </button>
          <div className="main-header-popup">
            <div>Comments from ASSET</div>
            <div>
              <Button
                className="add-new-button"
                shape="round"
                onClick={(e) => openModalNew(e)}
              >
                Add New
              </Button>
            </div>
          </div>
          <thead className="d-flex mb-7 headtable">
            <div className="tools-area mb-6 flex md:flex-row sm:flex-col md:items-center sm:items-start justify-between searchinput">
              <div className="select-label entriestable">
                <span>Show</span>

                <select
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  className="w-20 mx-3 px-2 py-1 rounded-full outile-none focus:outline-none table_count_action"
                >
                  {itemsPerPageOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <span>entries</span>
              </div>

              <div className="search-label sm:mt-2 md:mt-0 tablesearch">
                <span className="mr-3">Search : </span>
                <input className="w-16 px-6 py-2 rounded-full outile-none focus:outline-none table_count_action w-48 " />
              </div>
            </div>
          </thead>
          <table className="table-auto w-100 child-table tablesection">
            <thead>
              <tr>
                <th className="cursor-pointer header-table1">
                  <div className="flex flex-row items-center justify-between">
                    <span className=""></span>
                    <span className="mr-4"></span>
                  </div>
                </th>
                <th className="cursor-pointer header-table2">
                  <div className="flex flex-row items-center justify-between">
                    <span className=""> Comment </span>
                    <span className="mr-4"></span>
                  </div>
                </th>

                <th className="cursor-pointer header-table3">
                  <div className="flex flex-row items-center justify-between">
                    <span className=""> Date </span>
                    <span className="mr-4"></span>
                  </div>
                </th>

                <th className="cursor-pointer header-table4">
                  <div className="flex flex-row items-center justify-between">
                    <span className=""> Notes Written By </span>
                    <span className="mr-4"></span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="mt-4">
              <>
                {commentsdata1.map((item) => (
                  <tr>
                    <>
                      <td className="edit-delete-button">
                        <span
                          className="edit-icon"
                          data-toggle="modal"
                          data-target="#caseNotesPopup"
                          onClick={(e) =>
                            openModalEdit(
                              e,
                              item?.comment,
                              item?.id,
                              item?.schoolId,
                              item?.commentsFrom,
                              item?.userId
                            )
                          }
                        >
                          <i class="fal fa-edit" aria-hidden="true"></i>
                        </span>
                        <span className="delete-icon">
                          <a
                            href="#"
                            data-id="12"
                            data-value="PL"
                            onClick={(e) =>
                              deletButton(
                                e,
                                item?.id,
                                item?.schoolId,
                                item?.userId
                              )
                            }
                          >
                            <i class="fal fa-trash-alt" aria-hidden="true"></i>
                          </a>
                        </span>
                      </td>

                      <td>{item?.comment}</td>
                      <td>{item?.stringCreatedDate}</td>
                      <td>{item?.writtenBy}</td>
                    </>
                  </tr>
                ))}
              </>
            </tbody>
          </table>
          <thead className="d-flex mb-7 headtable">
            <div className="tools-area mb-6 flex md:flex-row sm:flex-col md:items-center sm:items-start justify-between searchinput">
              <div className="select-label entriestable">
                <span>
                  Show {commentsdata1.length} of {paginationvalue} entries
                </span>
              </div>
              <>
                <div className="search-label sm:mt-2 md:mt-0 tablesearch">
                  <span className="mr-3">
                    <Button
                      onClick={() => handlePageChange(currentPage - 1)}
                      className="previous-button"
                    >
                      Previous
                    </Button>
                    {Array.from({ length: totalPages }, (_, index) => (
                      <Button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        className={currentPage === index + 1 ? "active" : ""}
                      >
                        {index + 1}
                      </Button>
                    ))}
                    <Button
                      onClick={() => handlePageChange(currentPage + 1)}
                      className="next-button"
                    >
                      Next
                    </Button>
                  </span>
                </div>
              </>
            </div>
          </thead>
        </Modal>
        {/* /////////////////////////////////////////////////////////// modal comments for assets ends /////////////////////////////////////////////////////////// */}

        {/* /////////////////////////////////////////////////////////// modal La starts /////////////////////////////////////////////////////////// */}
        <Modal
          isOpen={modalIsOpenLa}
          onRequestClose={closeModalLa}
          ariaHideApp={false}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <button className="btn-close" onClick={closeModalLa}>
            X
          </button>
          <div className="main-header-popup">
            <div>Comments from LA</div>

            {/* {commentsdataLa.length != 0 ? (
              ""
            ) : ( */}
            <div>
              <Button
                className="add-new-button"
                shape="round"
                onClick={(e) => openModalNew(e)}
              >
                Add New
              </Button>
            </div>
            {/* )} */}
          </div>
          <thead className="d-flex mb-7 headtable">
            <div className="tools-area mb-6 flex md:flex-row sm:flex-col md:items-center sm:items-start justify-between searchinput">
              <div className="select-label entriestable">
                <span>Show</span>

                <select
                  value={itemsPerPageLa}
                  onChange={handleItemsPerPageChange}
                  className="w-20 mx-3 px-2 py-1 rounded-full outile-none focus:outline-none table_count_action"
                >
                  {itemsPerPageOptions1.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <span>entries</span>
              </div>

              <div className="search-label sm:mt-2 md:mt-0 tablesearch">
                <span className="mr-3">Search : </span>
                <input className="w-16 px-6 py-2 rounded-full outile-none focus:outline-none table_count_action w-48" />
              </div>
            </div>
          </thead>
          <table className="table-auto w-100 child-table tablesection">
            <thead>
              <tr>
                <th className="cursor-pointer header-table1">
                  <div className="flex flex-row items-center justify-between">
                    <span className=""></span>
                    <span className="mr-4"></span>
                  </div>
                </th>
                <th className="cursor-pointer header-table2">
                  <div className="flex flex-row items-center justify-between">
                    <span className=""> Comment </span>
                    <span className="mr-4"></span>
                  </div>
                </th>

                <th className="cursor-pointer header-table3">
                  <div className="flex flex-row items-center justify-between">
                    <span className=""> Date </span>
                    <span className="mr-4"></span>
                  </div>
                </th>

                <th className="cursor-pointer header-table4">
                  <div className="flex flex-row items-center justify-between">
                    <span className=""> Notes Written By </span>
                    <span className="mr-4"></span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="mt-4">
              <>
                {commentsdata3.map(
                  (item) => (
                  
                    (
                      <tr>
                        <>
                          <td className="edit-delete-button">
                            <span
                              className="edit-icon"
                              data-toggle="modal"
                              data-target="#caseNotesPopup"
                              onClick={(e) =>
                                openModalEdit(
                                  e,
                                  item?.comment,
                                  item?.id,
                                  item?.schoolId,
                                  item?.commentsFrom,
                                  item?.userId
                                )
                              }
                            >
                              <i class="fal fa-edit" aria-hidden="true"></i>
                            </span>
                            <span className="delete-icon">
                              <a
                                href="#"
                                data-id="12"
                                data-value="PL"
                                onClick={(e) =>
                                  deletButton(
                                    e,
                                    item?.id,
                                    item?.schoolId,
                                    item?.userId
                                  )
                                }
                              >
                                <i
                                  class="fal fa-trash-alt"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </span>
                          </td>
                          <td>{item?.comment}</td>
                          <td>{item?.stringCreatedDate}</td>
                          <td>{item?.writtenBy}</td>
                        </>
                      </tr>
                    )
                  )
                )}
              </>
            </tbody>
          </table>
          <thead className="d-flex mb-7 headtable">
            <div className="tools-area mb-6 flex md:flex-row sm:flex-col md:items-center sm:items-start justify-between searchinput">
              <div className="select-label entriestable">
                <span>
                  Show {commentsdata3.length} of {latestcomm.length} entries
                </span>
              </div>

              <div className="search-label sm:mt-2 md:mt-0 tablesearch">
                <span className="mr-3">
                  <Button
                    onClick={() => handleItemsPageChangeLa(Lapage - 1)}
                    className="previous-button"
                  >
                    Previous
                  </Button>
                  {Array.from({ length: totalPagesLa }, (_, index) => (
                    <Button
                      key={index}
                      onClick={() => handleItemsPageChangeLa(index + 1)}
                      className={Lapage === index + 1 ? "active" : ""}
                    >
                      {index + 1}
                    </Button>
                  ))}
                  <Button
                    onClick={() => handleItemsPageChangeLa(Lapage + 1)}
                    className="next-button"
                  >
                    Next
                  </Button>
                </span>
              </div>
            </div>
          </thead>
        </Modal>
        {/* /////////////////////////////////////////////////////////// modal La ends /////////////////////////////////////////////////////////// */}

        {/* /////////////////////////////////////////////////////////// add comment popup starts /////////////////////////////////////////////////////////// */}
        <Modal
          isOpen={addComment}
          onRequestClose={(e) => setAddComment(false)}
          ariaHideApp={false}
          style={customStyles}
          contentLabel="Example Modal"
          id="comment-asset"
        >
          <Form layout="vertical" name="add_comment">
            <Col md={24} xs={24}>
              <button
                className="comment-btn-close"
                onClick={(e) => setAddComment(false)}
              >
                X
              </button>

              <div className="comment-section-heading">Enter New Comment</div>
            </Col>

            <thead className="mb-7 commentTable">
              <div className="comment-button-section tools-area mb-6 flex md:flex-row sm:flex-col md:items-center sm:items-start justify-between searchinput">
                <Col md={12} xs={24}>
                  <Form.Item name="comment" label="Comment">
                    <textarea onChange={getCommentAdd} />
                  </Form.Item>
                </Col>
              </div>
              <div className="save-cancel-button">
                <Col md={5} xs={24}>
                  <Button
                    className="add-new-button"
                    shape="round"
                    onClick={addNewComment}
                  >
                    Save
                  </Button>
                </Col>
                <Col md={5} xs={24}>
                  <Button
                    className="add-new-button"
                    shape="round"
                    onClick={(e) => setAddComment(false)}
                  >
                    Cancel
                  </Button>
                </Col>
              </div>
            </thead>
          </Form>
        </Modal>
        {/* /////////////////////////////////////////////////////////// add comment popup ends /////////////////////////////////////////////////////////// */}

        {/* /////////////////////////////////////////////////////////// edit comment popup starts /////////////////////////////////////////////////////////// */}
        <Modal
          isOpen={EditComment}
          onRequestClose={(e) => setEditComment(false)}
          ariaHideApp={false}
          style={customStyles}
          contentLabel="Example Modal"
          className="edit-comment-modal"
        >
          <Form form={Editform} layout="vertical" name="edit_comment">
            <Col md={24} xs={24}>
              <button
                className="comment-btn-close"
                onClick={(e) => setEditComment(false)}
              >
                X
              </button>

              <div className="comment-section-heading">Edit Comment</div>
            </Col>

            <thead className="mb-7 commentTable">
              <div className="d-flex comment-button-section tools-area mb-6 flex md:flex-row sm:flex-col md:items-center sm:items-start justify-between searchinput">
                <Col md={24} xs={24}>
                  <Form.Item name="comment" label="Comment">
                    <textarea onChange={ChangedValue} />
                  </Form.Item>
                </Col>
              </div>
              <div className="save-cancel-button">
                <Col md={5} xs={24}>
                  <Button
                    className="add-new-button"
                    shape="round"
                    onClick={updateComment}
                  >
                    Save
                  </Button>
                </Col>
                <Col md={5} xs={24}>
                  <Button
                    className="add-new-button"
                    shape="round"
                    onClick={(e) => setEditComment(false)}
                  >
                    Cancel
                  </Button>
                </Col>
              </div>
            </thead>
          </Form>
        </Modal>
        {/* /////////////////////////////////////////////////////////// edit comment popup ends /////////////////////////////////////////////////////////// */}

        {/* <Header /> */}

        <div className="maintable">
          <center>
            <h2>Data Extraction Status</h2>
          </center>

          {/* /////////////////////////////////////////////////////////// data extraction table header starts /////////////////////////////////////////////////////////// */}
          <thead className="d-flex mb-7 headtable">
            <div class="tools-area mb-6 flex md:flex-row sm:flex-col md:items-center sm:items-start justify-between searchinput">
              <div class="select-label entriestable">
                <span>Show</span>
                <select
                  //defaultValue={itemsPage}
                  onChange={handleItemsPageChange}
                  className="w-20 mx-3 px-2 py-1 rounded-full outile-none focus:outline-none table_count_action"
                >
                  {itemsPerOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <span>entries</span>
              </div>
              &nbsp; &nbsp;
              {UserRoleId == 12 ? (
                <div className="select-label">
                  <span>Client</span>
                  <select
                    defaultValue={SelectedText}
                    className="w-48 mx-3 px-2 py-1 rounded-full outile-none focus:outline-none table_count_action changeclient"
                    onChange={handleClient}
                  >
                   
                    <option value={423685}>Barnet</option>
                    <option value={29679}>City of London</option>
                    <option value={423957}>Cumberland</option>
                    <option id="CIC" value={1934}>Lewisham CIC</option>
                    <option id='CIN,CP' value={1934}>Lewisham CiN/CP</option>
                    <option value={449245}>Milton Keynes</option>
                    <option value={423693}>NCC</option>
                    <option value={449219}>Westmorland</option>
                    <option value={449316}>Isle of Wight</option>
                    <option value={449263}>Hampshire</option>
                    
                  </select>
                </div>
              ) : (
                ""
              )}
              <div class="search-label sm:mt-2 md:mt-0 tablesearch">
                <span class="mr-3">Search : </span>
                <input
                  class="w-16 px-6 py-2 rounded-full outile-none focus:outline-none table_count_action w-48 report-search"
                  id="search-rpt"
                  // //value={Value}
                  // defaultValue={SearchDataValue}
                  
                  onChange={SearchData}
                />
                {/* <i class="fa fa-search " onClick={SearchData} /> */}
                <ul
                  className="result-data"
                  style={{ backgroundColor: "black", color: "white" }}
                >
                  {searchResults.map((result, index) => (
                    <li key={index}>{result}</li>
                  ))}
                </ul>
              </div>
            </div>
          </thead>
          {/* /////////////////////////////////////////////////////////// data extraction table header ends /////////////////////////////////////////////////////////// */}

          {/* /////////////////////////////////////////////////////////// data extraction table start starts /////////////////////////////////////////////////////////// */}
          <table className="table-auto w-100 child-table tablesection">
            <thead>
              <tr>
                <th
                  className="cursor-pointer"
                  style={{width:'244px'}}
                  onClick={() => setCustomSorting("schoolname")}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">School Name</span>
                    <span className="mr-4">{showArrow("schoolname")}</span>
                  </div>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => setCustomSorting("urn")}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">URN</span>
                    <span className="mr-4">{showArrow("urn")}</span>
                  </div>
                </th>
                <th
                  className="cursor-pointer"
                  style={{ width: "150px" }}
                  onClick={() => setCustomSorting("pupilcount")}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">No of pupils</span>
                    <span className="mr-4">{showArrow("pupilcount")}</span>
                  </div>
                </th>
                <th
                  className="cursor-pointer"
                  style={{ Width: "140px" }}
                  onClick={() => setCustomSorting("wondcount")}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">No of pupils on Wonde</span>
                    <span className="mr-4">{showArrow("wondcount")}</span>
                  </div>
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => setCustomSorting("attendanceStatus")}
                  style={{width:'167px'}}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">Attendance Status</span>
                    <span className="mr-4">
                      {showArrow("attendanceStatus")}
                    </span>
                  </div>
                </th>
                <th
                  className="cursor-pointer"
                  style={{ maxWidth: "267px" }}
                  onClick={() => setCustomSorting("dataExtractionName")}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">Wonde Administrator Contact Name</span>
                    <span className="mr-4">
                      {showArrow("dataExtractionName")}
                    </span>
                  </div>
                </th>
                <th
                  className="cursor-pointer"
                  style={{ maxWidth: "70px" }}
                  onClick={() => setCustomSorting("dataExtractionTel")}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">Tel</span>
                    <span className="mr-4">
                      {showArrow("dataExtractionTel")}
                    </span>
                  </div>
                </th>
                <th
                  className="cursor-pointer"
                 style={{width:'232px'}}
                  onClick={() => setCustomSorting("dataExtractionEmail")}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="">Wonde Administrator Email</span>
                    <span className="mr-4">
                      {showArrow("dataExtractionEmail")}
                    </span>
                  </div>
                </th>

                <th className="cursor-pointer" style={{ maxWidth: "131px" }}>
                  <div className="flex flex-row items-center justify-between">
                    <span className="">Comments from ASSET</span>
                    <span className="mr-4"></span>
                  </div>
                </th>

                <th className="cursor-pointer" style={{ width: "210px" }}>
                  <div className="flex flex-row items-center justify-between">
                    <span className="">Comments from LA</span>
                    <span className="mr-4"></span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="mt-4">
              {/* {tablemap.map((item) => hjv)} */}

              <>
                {commentsdata2.map((item, index) => (
                  <>
                    <tr>
                      <>
                        <td>
                          <span className="mr-4 icon">
                            <i
                              className="fa fa-plus"
                              onClick={(e) => StudentShow(item.schoolId, index)}
                            />
                          </span>
                          &nbsp;
                          {item?.schoolName}
                        </td>
                        <td>{item?.urn}</td>
                        <td className="schoolpupils">
                          {item?.noOfPupilInSchool}{" "}
                        </td>
                        <td className="schoolpupils ">
                          {item?.noOfPupilInWonde || ""}
                        </td>
                        <td className="selectattendance">
                          <div className="select-label">
                            <ToastContainer />
                            <Select
                            value={item.attendanceStatus}
                            style={{width:'60px'}}
                              //defaultValue={item?.attendanceStatus != '' ? item?.attendanceStatus : ''}
                              className="w-32 px-2 py-1 rounded-full outile-none focus:outline-none table_count_action client-status"
                              onChange={(e) =>
                                AttendanceUpdate(e, item.schoolId)
                              }
                            >
                              <option value=""></option>
                              <option className="client-option" value="wonde" disabled>
                                Wonde
                              </option>
                              <option
                                className="client-option"
                                value="awaiting_response"
                              >
                                Awaiting response
                              </option>
                              <option className="client-option" value="refused">
                                Refused
                              </option>
                              <option
                                className="client-option"
                                value="school_portal"
                              >
                               School Portal
                              </option>
                              <option
                                className="clien-option"
                                value="telephone"
                              >
                                Telephone
                              </option>
                              <option className="client-option" value="email">
                                Email
                              </option>
                              <option className="client-option" value="no_ims" >
                              No IMS
                              </option>
                            </Select>
                          </div>
                        </td>
                        <td onClick={(e) => setcontactname(index)}>
                          {contactname === index ? (
                            <>
                              <Input
                                className="tel-input"
                                id={`inputid-${index}`}
                                ref={inputRef}
                                defaultValue={item?.dataExtractionName}
                                onChange={(e) => setupdatedtel(e.target.value)}
                                onBlur={(e) =>
                                  handleEditComplete(item.schoolId, "name", e)
                                }
                                // onKeyPress={(e) =>
                                //   handleKeyPress(item.schoolId, "name", e)
                                // }
                              ></Input>
                            </>
                          ) : (
                            item?.dataExtractionName
                          )}
                        </td>
                        <td
                          id={`table-${index}`}
                          key={index}
                          onClick={(e) => setTelnum(index)}
                        >
                          {telnum === index ? (
                            <>
                              <Input
                                className="tel-input"
                                type="number"
                                id={`inputid-${index}`}
                                ref={inputRef}
                                defaultValue={item?.dataExtractionTel}
                                onChange={(e) => setupdatedtel(e.target.value)}
                                onBlur={(e) =>
                                  handleEditComplete(item.schoolId, "tel", e)
                                }

                                // onKeyPress={(e) =>
                                //   handleKeyPress(item.schoolId, "tel", e)
                                // }
                              ></Input>
                            </>
                          ) : (
                            item?.dataExtractionTel
                          )}
                        </td>
                        <td onClick={(e) => setEmailvalue(index)}>
                          {Emailvalue === index ? (
                            <>
                              <Input
                                className="tel-input"
                                type="email"
                                id={`inputid-${index}`}
                                ref={inputRef}
                                defaultValue={item?.dataExtractionEmail}
                                onChange={(e) => setupdatedtel(e.target.value)}
                                onBlur={(e) =>
                                  handleEditComplete(item.schoolId, "email", e)
                                }

                                // onKeyPress={(e) =>
                                //   handleKeyPress(item.schoolId, "email", e)
                                // }
                              ></Input>
                            </>
                          ) : (
                            item?.dataExtractionEmail
                          )}
                        </td>
                        <td onClick={(e) => openModal(e, item.schoolId)}>
                          {item?.latestCommentFromAsset}
                        </td>
                        <td onClick={(e) => openModalLa(e, item.schoolId)}>
                          {item?.latestCommentFromLA}
                        </td>
                      </>
                    </tr>

                    {truestate === index ? (
                      <>
                        <table className="wonde-table">
                          <thead>
                            <tr>
                              <th className="cursor-pointer">
                                <div className="flex flex-row items-center justify-between">
                                  <span className="">Full Name</span>
                                  <span className="mr-4"></span>
                                </div>
                              </th>
                              <th className="cursor-pointer">
                                <div className="flex flex-row items-center justify-between">
                                  <span className="">DOB</span>
                                  <span className="mr-4"></span>
                                </div>
                              </th>
                              <th
                                className="cursor-pointer"
                                style={{ maxWidth: "45px" }}
                              >
                                <div className="flex flex-row items-center justify-between">
                                  <span className="">Year group</span>
                                  <span className="mr-4"></span>
                                </div>
                              </th>

                              <th
                                className="cursor-pointer"
                                style={{ maxWidth: "45px" }}
                              >
                                <div className="flex flex-row items-center justify-between">
                                  <span className="">Wonde Status</span>
                                  <span className="mr-4"></span>
                                </div>
                              </th>
                            </tr>
                          </thead>

                          {innerTable.map((item) => (
                            <>
                              
                              <tr
                                class="childrow"
                                // onClick={(e) =>
                                //   HandleRedirection(item?.pupilId, e)
                                // }
                              >
                                <td>{item?.fullName}</td>

                                <td>{item?.dob}</td>

                                <td>{item?.yearGroup}</td>

                                <td>{item?.wondeStatus}</td>
                              </tr>
                            </>
                          ))}
                        </table>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ))}
              </>
              <tr className="table-pagination">
                <div className="select-label entriestable">
                  <span>
                    Show {commentsdata2.length} of {TableData.length} entries
                  </span>
                </div>
                <>
                  <div className="search-label sm:mt-2 md:mt-0 md:mt-0 tablesearch">
                    <span className="mr-3">
                      <Button
                        onClick={() => handleMainPagesChange(page - 1)}
                        className="previous-button"
                      >
                        Previous
                      </Button>
                      {Array.from({ length: totalPagesMain }, (_, index) => (
                        <Button
                          key={index}
                          onClick={() => handleMainPagesChange(index + 1)}
                          className={page === index + 1 ? "active" : ""}
                        >
                          {index + 1}
                        </Button>
                      ))}
                      <Button
                        onClick={() => handleMainPagesChange(page + 1)}
                        className="next-button"
                      >
                        Next
                      </Button>
                    </span>
                  </div>
                </>
              </tr>
            </tbody>
          </table>

          {/* /////////////////////////////////////////////////////////// data extraction table start ends /////////////////////////////////////////////////////////// */}
        </div>
      </div>
      {/* <div className="custom-head">
        <Footer />
      </div> */}
    </>
  );
};

export default ExtractionReport;
